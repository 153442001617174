import { getAgencyImagePath, l, lLink, lc } from '@utils/toolbox';
import { ConnectApi } from '@js/plugins/connectApi';
import { dateFormat } from '@js/utils/dateFns';

export class RecentSearches {
	constructor (config = {}) {
		const id = config.id || document.getElementById('recent-searches');
		if (!id) {
			return;
		}
		this.active = config.active || true;
		this.elems = {
			id: id,
			inner: id.getElementsByClassName('searches')[0],
			searches: id.getElementsByClassName('search')
		};
		this.recentSearches = [];
		this.timeouts = {};
		this.scrolled = 0;

		this.recentCityToCity = config.recentCityToCity || false;
		if (this.recentCityToCity) {
			this.cityFrom = config.cityFrom || false;
			this.cityTo = config.cityTo || false;
		}

		this.loadCachedSearches();
		this.events();
		this.start();

		setTimeout(() => this.stop(), 24 * 60 * 60); /* 24 hrs */
	}

	loadCachedSearches () {
		this.appendToSearchList(js_params.RecentSearchesData);
	}

	getRecentSearches () {
		ConnectApi('RecentSearches', 'getRecent', []).then(data => this.appendToSearchList(data));
	}

	getRecentCityToCity () {
		ConnectApi('RecentSearches', 'getRecentCityToCity', [this.cityFrom, this.cityTo]).then(data => this.appendToSearchList(data));
	}

	appendToSearchList (objSearches) {
		this.recentSearches = [...this.recentSearches, ...objSearches];

		// now render new searches...
		for (const search in objSearches) {
			if (objSearches.hasOwnProperty(search)) {
				if (this.recentCityToCity) {
					this.renderCityToCity(this.formatData(objSearches[search]));
				} else {
					this.render(this.formatData(objSearches[search]));
				}
			}
		}
	}

	formatData (data) {
		const pluralPerson = (data.persons > 1) ? l('numberPersonsShort.plural') : l('numberPersonsShort');

		return {
			href: `/${lLink('global.navigation.resultsLink')}?id=${data.id}`,
			dataFrom: data.from,
			iataFrom: data.fromIata,
			dataTo: data.to,
			iataTo: data.toIata,
			leavedate: data.leavedate,
			homedate: data.homedate,
			traveltime: data.traveltime,
			returntrip: data.returntrip,
			toText: l('recentSearches.scroller.to', 'till'),
			noOfPax: `${data.persons} ${pluralPerson}`,
			agencyImg: getAgencyImagePath({ code: data.code, size: 'small' }),
			price: lc(data.price)
		};
	}

	render (data) {
		const { href, dataFrom, dataTo, toText, noOfPax, agencyImg, price } = data;

		const html = `
				<span data-pseudolink="${href}" data-from="${dataFrom}" data-to="${dataTo}" class="search" tabindex="-1">
					<div>
						<p class="text from">${dataFrom} ${toText}</p>
						<p class="text to">${dataTo} <span class="no-of-passengers">(${noOfPax})</span></p>
					</div>
					<div>
						<img class="agency-icon" loading="lazy" src="${agencyImg}" width="54" height="14" alt="Lowest price agent logo">
						<p class="text price">${price}</p>
					</div>
				</span>
			`;

		this.elems.inner.insertAdjacentHTML('beforeend', html);
	}

	renderCityToCity (data) {
		const { href, dataFrom, dataTo, toText, noOfPax, agencyImg, price, iataFrom, iataTo, leavedate, homedate, traveltime, returntrip } = data;

		const html = `
				<span data-pseudolink="${href}" data-from="${dataFrom}" data-to="${dataTo}" class="search recentcitytocity" tabindex="-1">
					<div class="box-info">
						<p class="maintext">
							<span class="travelDate">${dateFormat(new Date(leavedate), 'MMM d')}</span>
							<span class="travelPlace">${dataFrom}<span class="travelIata">${iataFrom}</span></span>
							<span class="travelPlace"><span class="travelIata">${iataTo}</span>${dataTo}</span>
						</p>
						${returntrip
							? `<p class="maintext">
								<span class="travelDate">${dateFormat(new Date(homedate), 'MMM d')}</span>
								<span class="travelPlace">${dataTo}<span class="travelIata">${iataTo}</span></span>
								<span class="travelPlace"><span class="travelIata">${iataFrom}</span>${dataFrom}</span>
							</p>`
							: ``
						}
					</div>
					<div class="box-price">
						<span class="selling-info">
							<span class="no-of-passengers">${noOfPax}</span>
							<img class="agency-icon" loading="lazy" src="${agencyImg}" width="54" height="14" alt="Lowest price agent logo">
						</span>
						<p class="text price">${price}</p>
					</div>
				</span>
			`;

		this.elems.inner.insertAdjacentHTML('beforeend', html);
	}

	loop () {
		if (!this.active || !this.recentSearches.length) {
			return;
		}

		if (this.recentSearches.length < 15) {
			if (this.recentCityToCity && this.cityFrom && this.cityTo) {
				this.getRecentCityToCity();
			} else {
				this.getRecentSearches();
			}
		}

		const randomTimer = Math.floor(Math.random() * 1000) + 1000;

		this.timeouts.loop = setTimeout(() => {
			this.recentSearches.shift();
			this.doAnimation();
			this.loop();
		}, randomTimer);
	}

	doAnimation () {
		if (!this.active) {
			return;
		}

		const search = this.elems.searches[0];
		const scrollHeight = search.offsetHeight;

		search.classList.add('search-animated');

		this.scrolled -= scrollHeight;
		this.elems.inner.style.transform = `translate3d(0, ${this.scrolled}px, 0)`;

		setTimeout(() => {
			if (search.parentNode) {
				search.parentNode.removeChild(search);
			}

			this.elems.inner.style.top = Math.abs(this.scrolled) + 'px';
		}, 500);
	}

	start () {
		clearTimeout(this.timeouts.loop);

		this.active = true;
		this.loop();
	}

	stop () {
		this.active = false;
		clearTimeout(this.timeouts.loop);
	}

	events () {
		// If the page is hidden in the browser (example: the user opens a new tab)
		// then stop the scroller. This prevents further calls to ConnectApi.

		let hidden;
		let visibilityChange;
		let resizeTimer;

		if (typeof document.hidden !== 'undefined') {
			// Opera 12.10 and Firefox 18 and later support
			hidden = 'hidden';
			visibilityChange = 'visibilitychange';
		}

		if (typeof document.addEventListener !== 'undefined' || typeof document.hidden !== 'undefined') {
			document.addEventListener(visibilityChange, () => {
				clearTimeout(this.timeouts.animate);

				if (document.visibilityState === 'hidden') {
					this.stop();
				}

				if (document.visibilityState === 'visible') {
					// 1.5 second delay - allows enough time to select a result
					this.timeouts.animate = setTimeout(() => this.start(), 2000);
				}
			});
		}

		this.elems.id.addEventListener('mouseenter', () => this.stop());

		this.elems.id.addEventListener('mouseleave', () => {
			clearTimeout(this.timeouts.animate);

			this.timeouts.animate = setTimeout(() => this.start(), 1000);
		});

		// Pause the slider when the window is being resized.
		window.addEventListener('resize', () => {
			clearTimeout(resizeTimer);

			this.stop();

			resizeTimer = setTimeout(() => this.start(), 1000);
		});
	}
}
